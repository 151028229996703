import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import useFetch from 'use-http';
import * as Yup from 'yup';
import FlowLayout from 'components/base/FlowLayout';
import { md2react } from 'helpers/parser';
import useHubspot from 'hooks/use-hubspot';

export interface ReferralsFormModel {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  coreMarkDivision: string;
  companyOther: string;
  referfirstname: string;
  referlastname: string;
  referemail: string;
  refercompany: string;
  referphone: string;
}

const initialValues: ReferralsFormModel = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  coreMarkDivision: '',
  companyOther: '',
  referfirstname: '',
  referlastname: '',
  referemail: '',
  refercompany: '',
  referphone: '',
};

const schema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Please provide valid email')
    .required('This field is required'),
  company: Yup.string().required('This field is required'),
  coreMarkDivision: Yup.string().when('company', {
    is: 'Core-Mark',
    then: Yup.string().required('This field is required'),
  }),
  companyOther: Yup.string().when('company', {
    is: 'Other',
    then: Yup.string().required('This field is required'),
  }),
  referfirstname: Yup.string().required('This field is required'),
  referlastname: Yup.string().required('This field is required'),
  referemail: Yup.string()
    .email('Please provide valid email')
    .required('This field is required'),
  refercompany: Yup.string().required('This field is required'),
  referphone: Yup.string().required('This field is required'),
});

const ReferralsContent = ({
  title,
  body,
  successMessage,
  forceSuccess, // This is only for preview mode
}) => {
  const { post, response, error } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/3113e6e4-7282-42c3-b0fb-4dae0908d33a',
  ); // useFetch first to get post so you can feed to useForm

  const gtmVariables = {
    event: 'Send Referrals',
    'gtm.elementClasses': 'send-referrals',
    'gtm.elementId': 'send-referrals-form',
  };

  const {
    values: {
      firstname,
      lastname,
      email,
      company,
      coreMarkDivision,
      companyOther,
      referfirstname,
      referlastname,
      referemail,
      refercompany,
      referphone,
    },
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
  } = useHubspot({
    initialValues,
    schema,
    post,
    referrals: true,
    gtmVariables,
  });

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500 -mt-8 mb-8">{errors[name]}</div>
    ) : null;
  };

  return (
    <FlowLayout>
      {response?.ok || forceSuccess ? (
        <div>
          <h2 className="mb-3.5 font-semibold text-4xl tracking-tightest">
            {successMessage.title}
          </h2>
          <div className="mb-6 text-lg">{md2react(successMessage.body)}</div>

          <Link to="/" className="u-cta-link">
            Take me home
          </Link>
        </div>
      ) : (
        <div className="lg:pt-32 ReferralsForm">
          <h2 className="mb-3.5 font-semibold text-4xl tracking-tightest">
            {title}
          </h2>
          <div className="mb-6 text-lg">{md2react(body)}</div>

          <form
            className="mt-12"
            action="/"
            method="post"
            onSubmit={handleSubmit}
          >
            <h3 className="font-semibold text-2xl leading-none tracking-tighter mb-8">
              About me
            </h3>
            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="firstname">
                  First Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="firstname" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="lastname">
                  Last Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="lastname" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="email">
                  Email Address
                </label>
                <input
                  className="u-input"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="email" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="company">
                  Company
                </label>
                <div className="u-select">
                  <select
                    name="company"
                    value={company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled>
                      Select your company
                    </option>
                    <option value="Atlantic Dominion">Atlantic Dominion</option>
                    <option value="BTC">BTC</option>
                    <option value="Century Distributing">
                      Century Distributing
                    </option>
                    <option value="Core-Mark">Core-Mark</option>
                    <option value="Corr-Williams">Corr-Williams</option>
                    <option value="Doyles Sheehan">Doyles Sheehan</option>
                    <option value="Eby Brown">Eby Brown</option>
                    <option value="GSC">BTC</option>
                    <option value="HLA">HLA</option>
                    <option value="Imperial">Imperial</option>
                    <option value="McAneny">McAneny</option>
                    <option value="MR Williams">MR Williams</option>
                    <option value="MTC">MTC</option>
                    <option value="Paylidify">Paylidify</option>
                    <option value="S. Abraham & Sons">S. Abraham & Sons</option>
                    <option value="Stewart">Stewart</option>
                    <option value="Team Modern">Team Modern</option>
                    <option value="Team Sledd">Team Sledd</option>
                    <option value="W.L. Petrey">W.L. Petrey</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <ErrorMessage name="company" />
              </div>
            </div>

            {company === 'Core-Mark' ? (
              <div className="md:flex md:space-x-5">
                <div className="flex-1">
                  <label className="u-label" htmlFor="coreMarkDivision">
                    Core-Mark Divisions
                  </label>
                  <div className="u-select">
                    <select
                      name="coreMarkDivision"
                      value={coreMarkDivision}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected>
                        Select your division
                      </option>
                      <option value="Albuquerque - 048">
                        Albuquerque - 048
                      </option>
                      <option value="Atlanta - 178">Atlanta - 178</option>
                      <option value="Carolina - 280">Carolina - 280</option>
                      <option value="Fort Worth - 075">Fort Worth - 075</option>
                      <option value="Tampa - 140">Tampa - 140</option>
                      <option value="Iowa - 185">Iowa - 185</option>
                      <option value="Kentucky - 175">Kentucky - 175</option>
                      <option value="New England - 081">
                        New England - 081
                      </option>
                      <option value="Northern New England - 150">
                        Northern New England - 150
                      </option>
                      <option value="Ohio - 077">Ohio - 077</option>
                      <option value="Pennsylvania - 281">
                        Pennsylvania - 281
                      </option>
                      <option value="Bakersfield - 023">
                        Bakersfield - 023
                      </option>
                      <option value="Corona - 035">Corona - 035</option>
                      <option value="Denver - 256">Denver - 256</option>
                      <option value="Forrest City - 160">
                        Forrest City - 160
                      </option>
                      <option value="Hayward - 007">Hayward - 007</option>
                      <option value="Las Vegas - 065">Las Vegas - 065</option>
                      <option value="Los Angeles - 021">
                        Los Angeles - 021
                      </option>
                      <option value="Minneapolis - 170">
                        Minneapolis - 170
                      </option>
                      <option value="Portland - 044">Portland - 044</option>
                      <option value="Sacramento - 016">Sacramento - 016</option>
                      <option value="Salt Lake - 071">Salt Lake - 071</option>
                      <option value="Spokane - 053">Spokane - 053</option>
                    </select>
                  </div>
                  <ErrorMessage name="coreMarkDivision" />
                </div>
              </div>
            ) : company === 'Other' ? (
              <div className="md:flex md:space-x-5">
                <div className="flex-1">
                  <label className="u-label" htmlFor="companyOther">
                    Please enter your company name
                  </label>
                  <input
                    className="u-input"
                    type="text"
                    name="companyOther"
                    id="companyOther"
                    value={companyOther}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="companyOther" />
                </div>
              </div>
            ) : null}

            <h3 className="font-semibold text-2xl leading-none tracking-tighter mb-8 mt-4">
              I'd like to refer
            </h3>
            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="referfirstname">
                  First Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="referfirstname"
                  id="referfirstname"
                  value={referfirstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="referfirstname" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="referlastname">
                  Last Name
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="referlastname"
                  id="referlastname"
                  value={referlastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="referlastname" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="referemail">
                  Email Address
                </label>
                <input
                  className="u-input"
                  type="email"
                  name="referemail"
                  id="referemail"
                  value={referemail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="referemail" />
              </div>
              <div className="flex-1">
                <label className="u-label" htmlFor="refercompany">
                  Company
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="refercompany"
                  id="refercompany"
                  value={refercompany}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="refercompany" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <label className="u-label" htmlFor="referemail">
                  Phone Number
                </label>
                <input
                  className="u-input"
                  type="text"
                  name="referphone"
                  id="referphone"
                  value={referphone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="referphone" />
              </div>
              <div className="flex-1" />
            </div>

            <div className="mt-14 space-y-6 md:space-x-7">
              <button type="submit" className="u-btn u-btn--green">
                Refer Now
              </button>
            </div>

            {error && (
              <div className="text-red-500 mt-4">{response.data?.message}</div>
            )}
          </form>
        </div>
      )}
    </FlowLayout>
  );
};

export const ReferralsPreview = ({ entry }) => {
  const [forceSuccess, setForceSuccess] = useState(false);

  return (
    <div>
      <div
        className="text-white bg-primary px-2.5 py-1.5 my-2 rounded-full inline-block text-xs cursor-pointer"
        onClick={() => {
          setForceSuccess(!forceSuccess);
        }}
      >
        {forceSuccess ? 'Show Form' : 'Show Sucess Message'}
      </div>
      <hr />
      <ReferralsContent
        {...entry.get('data').toJS()}
        forceSuccess={forceSuccess}
      />
    </div>
  );
};

const Referrals = ({
  data: {
    markdownRemark: { frontmatter, rawMarkdownBody },
  },
}) => {
  return <ReferralsContent {...frontmatter} body={rawMarkdownBody} />;
};

export const pageQuery = graphql`
  query ReferralsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/referrals/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        title
        successMessage {
          title
          body
        }
      }
      rawMarkdownBody
    }
  }
`;

export default Referrals;
